<template>
  <div>
    <div class="layeditor pt-5 pb-5">
      <pre 
        class="layeditor__overlay layeditor__common" 
        v-html="convertedAnswer"
        ref="overlay"
      />
      <textarea 
        class="layeditor__textarea layeditor__common"
        spellcheck="false" 
        :value="text"
        @click="onClick" 
        @input="answer.content = $event.target.value"
        ref="textarea"
      ></textarea>
    </div>
    <div class="mt-6">
      <span>{{ answerContentLength }}文字</span>
      <span v-if="isEnoughContent">
        <v-icon style="font-size: 1em">
          mdi-check
        </v-icon>
      </span>
      <span v-else>
        （推奨文字数まで {{ 700 - answerContentLength }} 文字）
      </span>
    </div>   
  </div> 
</template>

<style lang="scss">
%layeditor__common {
  position: absolute;
  width: 100%;
  height: 600px;
  border-radius: 4px;
  font-family: Roboto, sans-serif;
  white-space: pre-wrap;
  overflow: scroll;
}

.layeditor{
  position: relative;
  height: 600px;

  &__textarea{
    @extend %layeditor__common;
    position: absolute;
    border: 2px solid rgb(118, 118, 118);
    padding: 10px;
  }
  &__overlay{
    @extend %layeditor__common;
    color: transparent;
    padding: 12px;
    padding-bottom: 50px;
    background-color: #e8f2ff !important;
  }
  & .highlight{
    animation: 1s ease forwards;
    border-bottom: 1px dashed #EA1537;
    padding-bottom: 1px;
    margin-bottom: -3px;
    &.is-selected{
      background: rgba(234, 21, 55, 0.2);
    }
  }
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "AutoCorrectionEditor",
  props: {
    text: {
      type: String,
      required: false,
      default: ""
    }
  },
  computed: {
    ...mapGetters({
      newNotes: "newNotes",
      selectedNoteId: "selectedNoteId",
      answer: "anytimeConsultationAnswer",
    }),
    convertedAnswer() {
      if (this.text == null) return "";

      let result = this.text;
      this.newNotes.forEach(note => {
        const classes = ['highlight']
        if(note.id === this.selectedNoteId){
          classes.push('is-selected')
        }
        result = result.replace(note.answer, `<span class='${classes.join(" ")}' data-note-id=${note.id}>${note.answer}</span>`)
      });
      return result;
    },
    answerContentLength(){
      if (this.text === null) return 0;
      return this.text.length;
    },
    isEnoughContent(){
      return this.answerContentLength >= 700;
    }
  },
  mounted(){
    this.$refs.textarea.addEventListener('scroll', this.onScroll);
  },
  methods:{
    ...mapActions([
      "selecteNoteId",
    ]),
    onScroll(event){
      const textarea = event.target;
      this.$refs.overlay.scrollTop = textarea.scrollTop;
    },
    onClick(event){
      const x = event.clientX;
      const y = event.clientY;
      this.$refs.textarea.style.visibility = 'hidden';
      const element = document.elementFromPoint(x, y)
      this.$refs.textarea.style.visibility = 'visible';
      if(element == null) return;
      if(element.dataset.noteId == null) return;
      this.selecteNoteId(Number(element.dataset.noteId))
    }
  }
};
</script>
