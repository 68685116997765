<template>
  <div class="l-anytime-consultation">
    <div class="l-anytime-consultation__main flex-grow-1">
      <div class="pink--text" v-if="errorMessage != null">
        {{ errorMessage }}
      </div>

      <v-btn :to="{ path: '/anytime_consultations', query: { tab_id: tabId } }" variant="text" color="pink">
        一覧へ戻る
      </v-btn>

      <!-- 事前問診 -->
        <anytime-consultation-inquiry
          :class="{ 'pa-0': true, 'px-6': true, 'mt-3': true, 'is-minimum': isSearchbarMaximum }" />
      <!--/ 事前問診 -->

      <!-- 関連情報 -->
      <template v-if="!isSearchbarMaximum">
        <recommend-journals ref="recommend-journals" />
      </template>
      <!--// 関連情報 -->

      <div class="l-anytime-consultation-answer d-flex flex-column flex-sm-row pa-0 pa-sm-6">
          <div :class="{ 'l-anytime-consultation-answer__left': true, 'w0': isSearchbarMaximum }">
          <!-- サイドバー -->
            <anytime-consultation-addon v-if="consultation.userId != null" ref="addon" :active="answer.editable"
              :user-id="consultation.userId" :child-id="consultation.childId" />
          <!--/ サイドバー -->
        </div>
        <div class="l-anytime-consultation-answer__right pa-0 ma-sm-2">
          <div class="pa-6 p-answer-form">
            <div>
              <h3 style="color:#D63864; font-size: 1.17em; font-weight: bold;">
                相談員記入欄
              </h3>
              <div class="pt-2">
                <b>回答状況</b>
                <span class="pr-3">： {{ answer.status }}</span>
              </div>
              <div>
                <b>更新日時</b>
                <span class="pr-3">： {{ answer.updatedAt }}</span>
              </div>
              <div>
                <b>記入者</b>
                <span class="pr-3">： {{ answer.doctorName }}</span>
              </div>
              <div>
                <b>送信日時</b>
                <span class="pr-3">： {{ answer.sentAt }}</span>
              </div>
            </div>
            <!-- 回答記入 -->
            <div class="pt-2">
              <v-textarea
                v-model="answer.complaint"
                variant="outlined"
                hide-details
                name="complaint"
                label="主訴（必須）*"
                rows="2"
                :readonly="!answer.editable"
                required
                @focus="isComplaintFocus = true"
                @blur="isComplaintFocus = false"
              />
              <div class="mt-5 mb-5">
                <div v-if="answer.editable && isComplaintFocus" style="color: #FB0606;">
                  ■非医療者にもわかる言葉で書いてください。<br>
                  ■個人を特定できる情報は入力しないでください（妊娠週数、月齢など）<br>
                  ※自治体・企業への共有、相談事例として公開することがあるため<br>
                </div>
              </div>
              <midwife-diagnosis v-if="consultation.department_type === 'midwife'" v-model:karte="answer"
                  :active="answer.editable" />
              <diagnosis v-else v-model:karte="answer" :department-data="consultation.department_type"
                :active="answer.editable" />

              <!-- 回答文エディタ -->
              <auto-correction-editor :text="answer.content"/>

              <!-- カルテ情報 相談内容報告欄 -->
              <div class="cooperated-area" v-if="consultation.cooperateWithClinic && answer.forReporting">
                <h3 style="color: #D63864; font-size: 1.17em; font-weight: bold;" class="text-center">
                  相談内容報告欄
                </h3>
                <div>
                  この相談はかかりつけクリニックに報告する必要があります。<br>
                  事前問診のボックス内（氏名・生年月日など）と以下の3つの欄に入力の内容をクリニックの医師が閲覧します。<br>
                  相談内容報告欄の記載をお願いします。
                </div>
                  <v-btn :disabled="!answer.editable" style="background-color: #ec407a; border-color: #ec407a;"
                    @click="copyFromKarteFields()">
                  カルテ内容を反映する
                  <v-icon icon="mdi-content-copy" />
                </v-btn>

                  <v-textarea v-model="answer.forReporting.complaint" variant="outlined" hide-details
                    name="complaint_for_reporting" label="報告用主訴（必須）*" required rows="2" :readonly="!answer.editable"
                    :disabled="!answer.editable" class="mb-4" />

                  <v-textarea v-model="answer.forReporting.diagnosis" variant="outlined" hide-details
                    name="diagnosis_for_reporting" label="報告用暫定診断（必須）*" required rows="2" :readonly="!answer.editable"
                    :disabled="!answer.editable" class="mb-4" />

                  <v-textarea v-model="answer.forReporting.content" variant="outlined"
                    name="answer-content_for_reporting" label="報告用回答（必須）*" required rows="18" counter
                    :readonly="!answer.editable" :disabled="!answer.editable" class="linefeed-label" />

              </div>
              <!--// カルテ情報 相談内容報告欄 -->

              <div class="p-btn-area d-flex justify-end flex-wrap">
                <template v-if="answer.editable">
                  <v-tooltip v-model="saveDraftMessageShow" location="top" text="Tooltip" :open-on-click="false" :open-on-hover="false" :close-delay="2000">
                    <template v-slot:activator="{ props }">
                      <v-btn v-bind="props" class="saveDraftBtn mx-0" variant="outlined" color="pink" @click="saveDraft();">下書き保存 </v-btn>
                    </template>
                    <span class="d-flex saveDraftMessage"><v-icon>mdi-check</v-icon>下書きに保存されました</span>
                  </v-tooltip>
                  <div class="d-flex ml-8">
                    <div class="requiredTimeSelect">
                      <v-select v-model="answer.requiredTime" :items="requiredTimeLabels" item-title="text"
                        item-value="value" required density="compact" variant="solo-filled" hide-details="false" />
                    </div>
                    <v-btn class="sendBtn" style="margin-left: 1px!important;" color="pink" tile
                      @click="completeAnswer()">
                      送信予約
                    </v-btn>
                  </div>
                </template>
                <template v-else-if="answer.sentAt == null">
                  <div class="requiredTimeSelect">
                    <v-select v-model="answer.requiredTime" :items="requiredTimeLabels" item-title="text"
                      item-value="value" disabled density="compact" variant="solo-filled" hide-details="false" />
                  </div>
                  <v-btn color="pink lighten-1" style="margin-left: 1px!important;" tile variant="outlined"
                    @click="cancelAnswer()">
                    送信予約取り消し
                  </v-btn>
                </template>
                <template v-else>
                  <div class="requiredTimeSelect">
                    <v-select v-model="answer.requiredTime" :items="requiredTimeLabels" item-title="text"
                      item-value="value" disabled density="compact" variant="solo-filled" hide-details />
                  </div>
                </template>
              </div>

              <!-- AI添削ボタン -->
              <v-card
                 v-if="answer.editable"
                class="mt-2 text-center pa-2"
              >
                <div class="d-flex justify-center" v-if="isCorrectionCompleted">
                  <v-icon color="rgb(21,100,189)">mdi-check</v-icon>
                  <span style="color: rgb(21,100,189)">添削済み</span>
                </div>
                <v-btn color="rgb(21,100,189)" size="default" class="px-8" @click="executeCorrection">
                  <svg class="mr-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.16927 17.5C3.93316 17.5 3.73524 17.4201 3.57552 17.2604C3.4158 17.1007 3.33594 16.9028 3.33594 16.6667V13.3333C3.33594 12.875 3.49913 12.4826 3.82552 12.1562C4.15191 11.8299 4.54427 11.6667 5.0026 11.6667H15.0026C15.4609 11.6667 15.8533 11.8299 16.1797 12.1562C16.5061 12.4826 16.6693 12.875 16.6693 13.3333V16.6667C16.6693 16.9028 16.5894 17.1007 16.4297 17.2604C16.27 17.4201 16.072 17.5 15.8359 17.5H4.16927ZM7.5026 10.8333C6.34983 10.8333 5.36719 10.4271 4.55469 9.61458C3.74219 8.80208 3.33594 7.81944 3.33594 6.66667C3.33594 5.51389 3.74219 4.53125 4.55469 3.71875C5.36719 2.90625 6.34983 2.5 7.5026 2.5H12.5026C13.6554 2.5 14.638 2.90625 15.4505 3.71875C16.263 4.53125 16.6693 5.51389 16.6693 6.66667C16.6693 7.81944 16.263 8.80208 15.4505 9.61458C14.638 10.4271 13.6554 10.8333 12.5026 10.8333H7.5026ZM7.5026 7.5C7.73872 7.5 7.93663 7.42014 8.09635 7.26042C8.25608 7.10069 8.33594 6.90278 8.33594 6.66667C8.33594 6.43056 8.25608 6.23264 8.09635 6.07292C7.93663 5.91319 7.73872 5.83333 7.5026 5.83333C7.26649 5.83333 7.06858 5.91319 6.90885 6.07292C6.74913 6.23264 6.66927 6.43056 6.66927 6.66667C6.66927 6.90278 6.74913 7.10069 6.90885 7.26042C7.06858 7.42014 7.26649 7.5 7.5026 7.5ZM12.5026 7.5C12.7387 7.5 12.9366 7.42014 13.0964 7.26042C13.2561 7.10069 13.3359 6.90278 13.3359 6.66667C13.3359 6.43056 13.2561 6.23264 13.0964 6.07292C12.9366 5.91319 12.7387 5.83333 12.5026 5.83333C12.2665 5.83333 12.0686 5.91319 11.9089 6.07292C11.7491 6.23264 11.6693 6.43056 11.6693 6.66667C11.6693 6.90278 11.7491 7.10069 11.9089 7.26042C12.0686 7.42014 12.2665 7.5 12.5026 7.5Z" fill="white"/>
                  </svg>
                  AIに添削をお願いする(テスト版)
                </v-btn>
                <div style="font-size: 10px">※AI添削は正確ではない場合があります、ご自身でも内容をご確認の上送信予約をしてください。</div>
              </v-card>
  
            </div>
          </div>
          <!-- お知らせ欄 -->
          <div class="mt-8">
            <p class="text-md-center text-pink">
              ※ 運営からのお知らせ ※
            </p>
            <div class="px-6">
              <p
                style="white-space: pre-wrap; line-height: 25px; word-break: break-all;"
                v-html="referenceInfo.content"
              />
            </div>
          </div>
          <!--/ お知らせ欄 -->
          <!-- 内容クリア -->
          <div class="text-center" v-if="!!answer.id && answer.editable">
            <v-btn
              color="pink"
              style="text-decoration: underline;"
              variant="text"
              @click="deleteAnswer()"
            >
              このカルテの内容をクリアする
            </v-btn>
          </div>
          <!--/ 内容クリア -->
        </div>
      </div>
    </div>
  <anytime-consultation-search-bar v-if="answer.editable"
          ref="searchbar"
          class="l-anytime-consultation__searchbar flex-grow-0 d-none d-sm-flex" :width-rank="widthRank"
          @updateWidthRank="updateWidthRank" />
  </div>
</template>

<script setup>
import { computed, ref, onMounted } from "vue";
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
const route = useRoute()

import Diagnosis from "./shared/Diagnosis.vue";
import MidwifeDiagnosis from "./shared/MidwifeDiagnosis.vue";
import RecommendJournals from "./shared/RecommendJournals.vue";
import AnytimeConsultationInquiry from "./shared/AnytimeConsultationInquiry.vue";
import AnytimeConsultationAddon from "./shared/AnytimeConsultationAddon.vue";
import AnytimeConsultationSearchBar from "./shared/AnytimeConsultationSearchBar.vue";
import AutoCorrectionEditor from "./shared/AutoCorrectionEditor.vue";

// ref
const searchbar = ref(null);

const tabId = ref(0);
const errorMessage = ref(null);
const widthRank = ref(2);
const isComplaintFocus = ref(false);
const requiredTimeLabels = ref([
  { text: "回答の作成時間", value: null },
  { text: "5分未満", value: "less_5" },
  { text: "5分以上10分未満", value: "between_5_10" },
  { text: "10分以上20分未満", value: "between_10_20" },
  { text: "20分以上30分未満", value: "between_20_30" },
  { text: "30分以上40分未満", value: "between_30_40" },
  { text: "40分以上50分未満", value: "between_40_50" },
  { text: "50分以上60分未満", value: "between_50_60" },
  { text: "60分以上", value: "more_60" },
]);
const saveDraftMessageShow = ref(false);
let showDraftMessageTimer = null;

const store = useStore()
const consultation = computed(() => store.getters.anytimeConsultation)
const answer = computed(() => store.getters.anytimeConsultationAnswer)
const referenceInfo = computed(() => store.getters.anytimeConsultationReferenceInfo)
const recommendJournals = computed(() => store.getters.recommendJournals)
const isCorrectionCompleted = computed(() => store.getters.isCorrectionCompleted)

const isAnswerContentFilled = computed(() => {
  return !!answer.value.complaint && (!!answer.value.branch_id || !!answer.value.original_disease) && !!answer.value.content;
});

const isRequiredTimeFilled = computed(() => {
  return !!answer.value.requiredTime;
});

const isContainedDraftMessage = computed(() => {
  return answer.value.content.match(/「ーーーーーーーーーーーー」/) || answer.value.content.match(/（回答本文）/);
});

const isSearchbarMaximum = computed(() => {
  return widthRank.value == 3;
});

onMounted(() => {
  // 一旦モデルを空にする
  store.dispatch('clearEpdsAnswers');
  store.dispatch("clearAnytimeConsultationObjects");

  store.dispatch("findAnytimeConsultation", {
    id: route.params.id
  });
  store.dispatch("findAnytimeConsultationAnswer", {
    anytime_consultation_id: route.params.id
  });
  store.dispatch("findAnytimeConsultationReferenceInfo", {
    anytime_consultation_id: route.params.id
  });
  store.dispatch("searchRecommendJournals", {
    anytimeConsultationId: route.params.id
  });
  store.dispatch("clearCorrectionData");
  tabId.value = route.query.tab_id;
});

const saveDraft = () => {
  store.dispatch("saveAnytimeConsultationAnswerDraft", {
    anytime_consultation_id: consultation.value.id,
    content: answer.value.content,
    complaint: answer.value.complaint,
    icd10_branch_id: answer.value.branch_id,
    icd10_original_disease: answer.value.original_disease,
    required_time: answer.value.requiredTime
  });

  store.dispatch('updateUser', store.getters.user);
  store.dispatch('updateChild', store.getters.child);

  clearTimeout(showDraftMessageTimer);      
  saveDraftMessageShow.value = true;
  showDraftMessageTimer = setTimeout(() => {
    saveDraftMessageShow.value = false;
  }, 2000);
};

const completeAnswer = () => {
  if (!isAnswerContentFilled.value) {
    return alert("記入欄に入力漏れがあります");
  }
  if (!isRequiredTimeFilled.value) {
    return alert("「回答の作成時間」を選択してください");
  }
  if (isContainedDraftMessage.value) {
    return alert("回答文にテンプレート文章が残っています");
  }

  dispatch("completeAnytimeConsultationAnswer", {
    anytime_consultation_id: consultation.value.id,
    content: answer.value.content,
    complaint: answer.value.complaint,
    icd10_branch_id: answer.value.branch_id,
    icd10_original_disease: answer.value.original_disease,
    required_time: answer.value.requiredTime
  });
  dispatch("findAnytimeConsultationReferenceInfo", {
    anytime_consultation_id: $route.params.id
  });
  tabId.value = $route.query.tab_id;

  store.dispatch('updateUser', store.getters.user);
  store.dispatch('updateChild', store.getters.child);
};

const cancelAnswer = () => {
  dispatch("cancelAnytimeConsultationAnswer", {
    anytime_consultation_id: consultation.value.id,
  }).then(() => {
    if (cancelResult.value == false) {
      errorMessage.value = "送信済みのため取り消しできませんでした";
      // 最新を再取得
      dispatch("findAnytimeConsultationAnswer", {
        anytime_consultation_id: $route.params.id
      });
    }
  });
};

const deleteAnswer = () => {
  if (confirm("入力された内容を全てクリアします。本当によろしいですか？")) {
    dispatch("deleteAnytimeConsultationAnswer", {
      anytime_consultation_id: consultation.value.id
    });
  }
};

const updateWidthRank = (newWidthRank) => {
  widthRank.value = newWidthRank;
};

const executeCorrection = () => {
  // もしサイドバーが閉じていたら開く
  if (widthRank.value == 1) updateWidthRank(2);
  searchbar.value.executeCorrection();
};
</script>

<style lang="scss">
.l-anytime-consultation {
  display: flex;
  overflow: auto;
  height: 100vh !important;
  width: 100%;
  font-size: 14px;

  &__main{
    @media (min-width: 600px) {
      max-height: 100vh;
      overflow: scroll;
    }
  }
  // &__searchbar{}
}

.l-anytime-consultation-answer {
  &__left {
    width: 360px;
    transition: width 0.3s 0s ease;

    @media (max-width: 600px) {
      width: 100%;
    }

    &.w0 {
      width: 0px;
    }
  }

  &__right {
    width: 100%;
  }

  .v-text-field--outlined:not(.v-input--is-focused),
  .v-text-field--outlined:not(.v-input--is-focused) .v-input__slot:hover {
    border: 2px solid rgba(0, 0, 0, .54);
  }

  // 入力欄の背景を薄青色にする
  .anytime-consultation-addon {
    .v-input.v-text-field:not(.v-input--disabled) .v-field__field {
      background-color: #e8f2ff !important;
    }
  }

  .diagnosis-form {
    .v-input.v-text-field:not(.v-input--disabled) .v-field__field {
      background-color: #e8f2ff !important;
    }
  }

  .v-input.v-text-field:not(.v-input--readonly) .v-field__field {
    background-color: #e8f2ff !important;
  }

  .parent-special-note .v-input.v-text-field:not(.v-input--disabled) {
    background-color: #e8f2ff !important;
  }

  .child-special-note .v-input.v-text-field:not(.v-input--disabled) {
    background-color: #e8f2ff !important;
  }

  /* vuetify3 対応 入力不可のラベルの透明度を1にする */
  .v-field--disabled {
    --v-disabled-opacity: 1 !important;
  }

  .p-btn-area {
    .requiredTimeSelect {
      max-width: 200px;

      .v-field--active {
        min-height: 36px !important;
        margin-top: 6px;
        border-radius: 0px !important;
        color: #fff !important;
        background-color: #EF5A8D !important;

        .v-field__field {
          background-color: #EF5A8D !important;
        }
      }

      .theme--light.v-icon {
        font-size: 14px;
        color: #fff !important;
      }

      .theme--light.v-label {
        font-size: 14px;
        color: #fff !important;
      }

      .v-select__selection-text {
        font-size: 14px;
        color: #fff;
      }

      .mdi-menu-down::before {
        font-size: 1.17em
      }
    }

    .saveDraftBtn{
      background: #FFFFFF;
      border: 1px solid #EF1A65;
      .v-btn__content .text-black {
        color: #EF1A65;
      }
    }
    .sendBtn {
      .v-btn__content {
        color: #fff;
      }
    }
  }
}

.p-answer-form {
  background-color: #fdeef3;

  .cooperated-area {
    border-top: 1px solid #D63864;
    padding-top: 16px;
    margin-top: 16px;
  }
}

.requiredTimeSelect {
  .v-select {
    height: 36px;
    margin-top: 6px;
  }

  .v-field {
    height: 36px;
    margin-top: 0 !important;
  }

  .v-field__field {
    height: 36px;
  }

  .v-field__input {
    padding-top: 6px !important;
  }
}

.v-theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: rgba(0, 0, 0, .12) !important;
  color: rgba(0, 0, 0, .26) !important;
}

.v-tooltip .v-overlay__content:has(.saveDraftMessage){
  background: #FFFFFF !important;
  border: 1px solid #EF1A65;
  color: #EF1A65 !important;
}
</style>
