import * as mutationTypes from "../mutation-types";

// const sampleNotes = [{ "id": 1, "type": "misreading_of_consultation", "answer": "お子様は10歳ということですね。", "reason": "子供の年齢が3歳と記載されているため", "fix": null, "isMarked": false, "isExists": true, "isDupulicated": false, "order": 0, "status": "new" }, { "id": 2, "type": "misreading_of_consultation", "answer": "小学校に通われていると思いますが、投稿しても構いません。", "reason": "3歳児は小学生ではないため", "fix": null, "isMarked": false, "isExists": true, "isDupulicated": false, "order": 0, "status": "new" }, { "id": 3, "type": "misreading_of_consultation", "answer": "お母様が罹ったのはA型のインフルエンザでしょうか？", "reason": "相談文にA型と明記されているため", "fix": null, "isMarked": false, "isExists": true, "isDupulicated": false, "order": 0, "status": "new" }, { "id": 4, "type": "grammatical_errors", "answer": "投稿しても", "reason": null, "fix": "登校しても", "isMarked": false, "isExists": true, "isDupulicated": false, "order": 0, "status": "new" }]

const state = {
  isCorrecting: false,
  isCorrectionSuccess: false,
  notes: [], // sampleNotes,
  selectedNoteId: undefined,
  markedNoteIds: []
};

const getters = {
  selectedNoteId: state => state.selectedNoteId,
  isCorrecting: state => state.isCorrecting,
  isCorrectionSuccess: state => state.isCorrectionSuccess,
  isCorrectionCompleted: (state, getters) => state.isCorrectionSuccess && getters.newNotes.length == 0,
  notesWithStatus: (state, getters, rootState) => {
    const content = rootState.anytimeConsultations.anytimeConsultationAnswer.content;
    return state.notes.map((note) => {
      const isMarked = getters.markedNoteIds.includes(note.id);
      const frequency = content.split(note.answer).length - 1; // 出現頻度
      const isExists = frequency > 0;
      const isDupulicated = frequency > 1;
      const order = (isMarked * 100 + !isExists * 10); // 数字が大きいほど後ろに表示される
      const status = !isMarked && isExists ? "new" : "solved";
      return {
        ...note,
        isMarked: isMarked,
        isExists: isExists,
        isDupulicated: isDupulicated,
        order: order,
        status: status,
      };
    });
  },
  newNotes: (state, getters) => {
    return getters.notesWithStatus.filter((note) => {
      return note.status == "new";
    });
  },
  orderedCorrectNoteList: (state, getters) => {
    return getters.notesWithStatus.slice().sort((a, b) => {
      return (a.order - b.order);
    }).filter((note) => {
      return !note.isDupulicated; // 重複しているものは表示しない
    });
  },
  sizeCorrectNoteListGroupByStatus: (state, getters) => {
    let result = { "new": 0, "solved": 0 };
    getters.notesWithStatus.forEach((note) => {
      result[note.status] += 1;
    });
    return result;
  },
  markedNoteIds: state => state.markedNoteIds,
};

const actions = {
  clearCorrectionData({ commit }) {
    commit(mutationTypes.ASSIGN_IS_CORRECTING, false);
    commit(mutationTypes.ASSIGN_IS_CORRECTION_SUCCESS, false);
    commit(mutationTypes.ASSIGN_RAW_CORRECT_NOTE, []);
    commit(mutationTypes.ASSIGN_SELECTED_NOTE_ID, undefined);
    commit(mutationTypes.CLEAR_MARKED_NOTE_IDS);
  },
  execCorrectionGpt({ commit }, { id, cable, answer }) {
    commit(mutationTypes.ASSIGN_IS_CORRECTING, true);
    commit(mutationTypes.ASSIGN_IS_CORRECTION_SUCCESS, false);
    commit(mutationTypes.ASSIGN_RAW_CORRECT_NOTE, []);
    commit(mutationTypes.ASSIGN_SELECTED_NOTE_ID, undefined);
    commit(mutationTypes.CLEAR_MARKED_NOTE_IDS);

    const channel = cable.subscriptions.create(
      {
        channel: "AnytimeConsultationChannel",
        id: id,
        answer: answer,
      },
      {
        received: (data) => {
          commit(mutationTypes.ASSIGN_RAW_CORRECT_NOTE, JSON.parse(data.notes));
          if (data.stop_reason == "end_turn") {
            channel.unsubscribe();
            commit(mutationTypes.ASSIGN_IS_CORRECTING, false);
            commit(mutationTypes.ASSIGN_IS_CORRECTION_SUCCESS, true);
          }
        },
      }
    );
  },
  selecteNoteId({ commit }, noteId) {
    // document.getElementsByClassName("layeditor")[0].scrollIntoView({behavior: "smooth"});
    
    // ============ 選択した要素をスクロールする ============
    const note = document.querySelector("span[data-note-id=\"" + noteId +"\"]"); // 対象のspan要素を取得
    if(note == null) return;  // 対象のspan要素が存在しない場合は処理を終了
    
    const overlay = document.getElementsByClassName("layeditor__overlay")[0];  // 対象のdiv要素を取得
    const textarea = document.getElementsByClassName("layeditor__textarea")[0];  // 対象のdiv要素を取得

    const spanPosition = note.offsetTop;
    const spanHeight = note.offsetHeight;

    const divScrollTop = overlay.scrollTop;
    const divHeight = overlay.clientHeight;

    // spanが表示されているかどうかを確認
    const isSpanVisible = spanPosition >= divScrollTop && (spanPosition + spanHeight) <= (divScrollTop + divHeight);

    if (!isSpanVisible) {
      // span要素の位置を中央にスクロールさせる
      const spanCenter = spanPosition + (spanHeight / 2);
      const divCenter = divHeight / 2;
      const scrollPosition = spanCenter - divCenter;

      // スムーズにスクロール
      overlay.scrollTo({
        top: scrollPosition,
        behavior: "smooth"  // スムーズなスクロールを指定
      });
      textarea.scrollTo({
        top: scrollPosition,
        behavior: "smooth"  // スムーズなスクロールを指定
      });
    }
    
    // ============ 選択した要素をスクロールする ============

    commit(mutationTypes.ASSIGN_SELECTED_NOTE_ID, noteId);
  },
  markSolved({ commit }, noteId) {
    commit(mutationTypes.MARK_NOTE_ID, noteId);
  },
  // markUnsolved({ commit }, noteId) {
  //   commit(mutationTypes.UNMARK_NOTE_ID, noteId);
  // },
};

const mutations = {
  [mutationTypes.ASSIGN_IS_CORRECTING](state, isCorrecting) {
    state.isCorrecting = isCorrecting;
  },
  [mutationTypes.ASSIGN_IS_CORRECTION_SUCCESS](state, isCorrectionSuccess) {
    state.isCorrectionSuccess = isCorrectionSuccess;
  },
  [mutationTypes.ASSIGN_RAW_CORRECT_NOTE](state, notes) {
    state.notes = notes;
  },
  [mutationTypes.ASSIGN_SELECTED_NOTE_ID](state, noteId) {
    state.selectedNoteId = noteId;
  },
  [mutationTypes.CLEAR_MARKED_NOTE_IDS](state) {
    state.markedNoteIds = [];
  },
  [mutationTypes.MARK_NOTE_ID](state, noteId) {
    if (!state.markedNoteIds.includes(noteId)) {
      state.markedNoteIds.push(noteId);
    }
  },
  // [mutationTypes.UNMARK_NOTE_ID](state, noteId) {
  //   if (state.markedNoteIds.includes(noteId)) {
  //     state.markedNoteIds = state.markedNoteIds.filter((id) => id != noteId);
  //   }
  // },
};

export default {
  state,
  getters,
  actions,
  mutations
};