<template>
  <div class="c-notecard" :class="{
    'is-selected': isSelected,
    'is-solved': note.status == 'solved',
    'is-edited': note.status == 'edited',
    'is-new': note.status == 'new'
  }" @click="selecteNoteId(note.id)" :key="note.id">
    <div class="c-notecard__inner mb-4 pa-3">
      <div v-if="note.type == 'misreading_of_consultation'">
        <div>
          <span class="c-notecard__type">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.91927 12.25C2.75399 12.25 2.61545 12.1941 2.50365 12.0823C2.39184 11.9705 2.33594 11.8319 2.33594 11.6667V9.33333C2.33594 9.0125 2.45017 8.73785 2.67865 8.50938C2.90712 8.2809 3.18177 8.16667 3.5026 8.16667H10.5026C10.8234 8.16667 11.0981 8.2809 11.3266 8.50938C11.555 8.73785 11.6693 9.0125 11.6693 9.33333V11.6667C11.6693 11.8319 11.6134 11.9705 11.5016 12.0823C11.3898 12.1941 11.2512 12.25 11.0859 12.25H2.91927ZM5.2526 7.58333C4.44566 7.58333 3.75781 7.29896 3.18906 6.73021C2.62031 6.16146 2.33594 5.47361 2.33594 4.66667C2.33594 3.85972 2.62031 3.17188 3.18906 2.60312C3.75781 2.03438 4.44566 1.75 5.2526 1.75H8.7526C9.55955 1.75 10.2474 2.03438 10.8161 2.60312C11.3849 3.17188 11.6693 3.85972 11.6693 4.66667C11.6693 5.47361 11.3849 6.16146 10.8161 6.73021C10.2474 7.29896 9.55955 7.58333 8.7526 7.58333H5.2526ZM5.2526 5.25C5.41788 5.25 5.55642 5.1941 5.66823 5.08229C5.78003 4.97049 5.83594 4.83194 5.83594 4.66667C5.83594 4.50139 5.78003 4.36285 5.66823 4.25104C5.55642 4.13924 5.41788 4.08333 5.2526 4.08333C5.08733 4.08333 4.94878 4.13924 4.83698 4.25104C4.72517 4.36285 4.66927 4.50139 4.66927 4.66667C4.66927 4.83194 4.72517 4.97049 4.83698 5.08229C4.94878 5.1941 5.08733 5.25 5.2526 5.25ZM8.7526 5.25C8.91788 5.25 9.05642 5.1941 9.16823 5.08229C9.28003 4.97049 9.33594 4.83194 9.33594 4.66667C9.33594 4.50139 9.28003 4.36285 9.16823 4.25104C9.05642 4.13924 8.91788 4.08333 8.7526 4.08333C8.58733 4.08333 8.44878 4.13924 8.33698 4.25104C8.22517 4.36285 8.16927 4.50139 8.16927 4.66667C8.16927 4.83194 8.22517 4.97049 8.33698 5.08229C8.44878 5.1941 8.58733 5.25 8.7526 5.25Z" fill="#1564BD"/>
            </svg>
            確認依頼
          </span>
          <div class="mt-1 messages">{{ note.answer }}</div>
          <div class="messages messages__reason" v-if="note.reason">{{ note.reason }}</div>
          <v-btn v-if="note.status !== 'solved'" outlined text color="rgb(21,100,189)" class="ma-0 mt-2 font-weight-bold" @click="markSolved(note.id)">
            問題ありません
          </v-btn>
        </div>
      </div>
      <div v-else-if="note.type == 'grammatical_errors'">
        <span class="c-notecard__type">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.91927 12.25C2.75399 12.25 2.61545 12.1941 2.50365 12.0823C2.39184 11.9705 2.33594 11.8319 2.33594 11.6667V9.33333C2.33594 9.0125 2.45017 8.73785 2.67865 8.50938C2.90712 8.2809 3.18177 8.16667 3.5026 8.16667H10.5026C10.8234 8.16667 11.0981 8.2809 11.3266 8.50938C11.555 8.73785 11.6693 9.0125 11.6693 9.33333V11.6667C11.6693 11.8319 11.6134 11.9705 11.5016 12.0823C11.3898 12.1941 11.2512 12.25 11.0859 12.25H2.91927ZM5.2526 7.58333C4.44566 7.58333 3.75781 7.29896 3.18906 6.73021C2.62031 6.16146 2.33594 5.47361 2.33594 4.66667C2.33594 3.85972 2.62031 3.17188 3.18906 2.60312C3.75781 2.03438 4.44566 1.75 5.2526 1.75H8.7526C9.55955 1.75 10.2474 2.03438 10.8161 2.60312C11.3849 3.17188 11.6693 3.85972 11.6693 4.66667C11.6693 5.47361 11.3849 6.16146 10.8161 6.73021C10.2474 7.29896 9.55955 7.58333 8.7526 7.58333H5.2526ZM5.2526 5.25C5.41788 5.25 5.55642 5.1941 5.66823 5.08229C5.78003 4.97049 5.83594 4.83194 5.83594 4.66667C5.83594 4.50139 5.78003 4.36285 5.66823 4.25104C5.55642 4.13924 5.41788 4.08333 5.2526 4.08333C5.08733 4.08333 4.94878 4.13924 4.83698 4.25104C4.72517 4.36285 4.66927 4.50139 4.66927 4.66667C4.66927 4.83194 4.72517 4.97049 4.83698 5.08229C4.94878 5.1941 5.08733 5.25 5.2526 5.25ZM8.7526 5.25C8.91788 5.25 9.05642 5.1941 9.16823 5.08229C9.28003 4.97049 9.33594 4.83194 9.33594 4.66667C9.33594 4.50139 9.28003 4.36285 9.16823 4.25104C9.05642 4.13924 8.91788 4.08333 8.7526 4.08333C8.58733 4.08333 8.44878 4.13924 8.33698 4.25104C8.22517 4.36285 8.16927 4.50139 8.16927 4.66667C8.16927 4.83194 8.22517 4.97049 8.33698 5.08229C8.44878 5.1941 8.58733 5.25 8.7526 5.25Z" fill="#1564BD"/>
          </svg>
          修正提案
        </span>

        <div class="mt-1 messages" v-html="hilightenBeforeAfter.answer" />
        → <span class="messages" v-html="hilightenBeforeAfter.fix" />
        <div class="d-flex mt-2" v-if="note.status == 'new'" >
          <v-btn outlined text color="#D72E4B" class="ma-0 mr-2 font-weight-bold" @click="fixTypo()">
            修正
          </v-btn>
          <v-btn outlined text color="rgb(21,100,189)" class="ma-0 mr-2 font-weight-bold" style="opacity: 1" @click="markSolved(note.id)">
            問題ありません
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.c-notecard {
  &__type {
    display: inline-flex;
    align-items: center;
    height: 21px;
    font-size: 12px;
    padding: 0px 6px;
    background: #E8F2FE;
    border-radius: 4px;
    color: rgb(21,100,189);

    .is-solved & {
      opacity: 0.5;
    }
  }

  .messages {
    font-size: 14px; 
    color: #333;
    &__reason{
      color: #A5A5A5;
    }
  }
  .messages .highlight{
    color: #D72E4B;
    text-decoration: underline;
  }

  &__inner {
    background-color: white;
    border-radius: 4px;
    border: 1px solid #ccc;
    cursor: pointer;
    transition: height 0.3s linear;

    .is-selected & {
      background: #ffecef !important;
    }

    .is-solved & {
      border: 1px solid #eee;      
    }

    .is-solved & .messages {
      opacity: 0.5;
    }
  }
}
</style>

<script>
function diffHighlight(beforeText, afterText) {
  // 最初から一致する部分を探す
  let start = 0;
  while (start < beforeText.length && start < afterText.length && beforeText[start] === afterText[start]) {
      start++;
  }

  // 最後から一致する部分を探す
  let endBefore = beforeText.length - 1;
  let endAfter = afterText.length - 1;
  while (endBefore >= start && endAfter >= start && beforeText[endBefore] === afterText[endAfter]) {
      endBefore--;
      endAfter--;
  }

  // 修正前の強調されたテキスト
  const highlightedBefore = 
      beforeText.slice(0, start) + 
      '<span class="highlight">' + beforeText.slice(start, endBefore + 1) + '</span>' + 
      beforeText.slice(endBefore + 1);

  // 修正後の強調されたテキスト
  const highlightedAfter = 
      afterText.slice(0, start) + 
      '<span class="highlight">' + afterText.slice(start, endAfter + 1) + '</span>' + 
      afterText.slice(endAfter + 1);

  return { highlightedBefore, highlightedAfter };
}
// diffHighlight('投稿しても', '登校しても');

import { mapGetters, mapActions } from "vuex";

export default {
  name: "AutoCorrectionList",
  props: {
    note: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters([
      "selectedNoteId",
      "anytimeConsultationAnswer",
      "markedNoteIds"
    ]),
    isSelected() {
      return this.note.id === this.selectedNoteId;
    },
    hilightenBeforeAfter() {
      if (this.note.type !== 'grammatical_errors') return;
      const result = diffHighlight(this.note.answer, this.note.fix); 
      return {
        answer: result.highlightedBefore,
        fix: result.highlightedAfter
      }
    }
  },
  methods: {
    ...mapActions([
      "selecteNoteId",
      "markSolved",
    ]),
    fixTypo() {
      // Note 
      // 本来ならビジネスロジックなのでVuexに移動したいが、Vuexのモジュールを超える必要があるので、一旦ここに記述
      this.anytimeConsultationAnswer.content = this.anytimeConsultationAnswer.content.replace(this.note.answer, this.note.fix)
      this.markSolved(this.note.id)
    },
  }
};
</script>
