<template>
  <div class="p-drawer">
    <!-- アイコン部 -->
    <div class="p-drawer__icons">
      <a
        :class="{ 'p-drawer__icons__expand': true, 'is-disable': isMaxWidth }"
        @click="expand"
      >
        <v-icon
          icon="mdi-play-circle"
          size="large"
        />
      </a>
      <a
        :class="{ 'p-drawer__icons__shrink': true, 'is-disable': isMinWidth }"
        @click="shrink"
      >
        <v-icon
          icon="mdi-play-circle"
          size="large"
        />
      </a>
    </div>

    <!-- 管理者向けダイアログ -->
    <v-dialog
      v-model="optionDialog"
      max-width="60%"
    >
      <v-col
        text-md-center
        style="position: relative"
      >
        <v-container style="background-color: #eee;">
          <div class="d-flex p-searchDetail">
            <div class="py-3 px-1 p-searchDetail__key">
              <div class="p-searchDetail__key__title">
                <b>並び順</b>
              </div>
              <div class="p-searchDetail__key__caption" />
            </div>
            <div class="py-3 px-1 d-flex flex-wrap p-searchDetail__input">
              <v-radio-group
                v-model="query.sortRules"
                inline
                density="compact"
                class="ma-0"
                hide-details
              >
                <v-radio
                  v-for="option in options.sortRules"
                  :key="option.value"
                  :label="option.text"
                  :value="option.value"
                />
              </v-radio-group>
            </div>
          </div>
          <div class="d-flex p-searchDetail">
            <div class="py-3 px-1 p-searchDetail__key">
              <div class="p-searchDetail__key__title">
                <b>スコア表示</b>
              </div>
              <div class="p-searchDetail__key__caption">
                (動作検証用)
              </div>
            </div>
            <div class="py-3 px-1 d-flex flex-wrap p-searchDetail__input">
              <div>
                <v-checkbox
                  v-model="query.displayScore"
                  label="検索スコアを表示する"
                  class="ma-2"
                  hide-details
                />
              </div>
            </div>
          </div>
        </v-container>
      </v-col>
    </v-dialog>

    <!-- 追加検索条件ダイアログ -->
    <v-dialog
      v-model="searchConditionDialog"
      max-width="60%"
    >
      <v-col
        text-md-center
        style="position: relative"
      >
        <v-container style="background-color: #eee;">
          <div
            v-if="query.departmentType == 'pediatrician'"
            class="d-flex p-searchDetail"
          >
            <div class="py-3 px-1 p-searchDetail__key">
              <div class="p-searchDetail__key__title">
                <b>年齢</b>
              </div>
              <div class="p-searchDetail__key__caption">
                子どもの年齢
              </div>
            </div>
            <div class="py-3 px-1 d-flex flex-wrap p-searchDetail__input">
              <div
                v-for="option in options.childAgeRank"
                :key="option.value"
              >
                <v-checkbox
                  v-model="query.childAgeRank"
                  :value="option.value"
                  :label="option.text"
                  class="ma-2"
                  hide-details
                />
              </div>
            </div>
          </div>
          <div
            v-if="query.departmentType == 'pediatrician'"
            class="d-flex p-searchDetail"
          >
            <div class="py-3 px-1 p-searchDetail__key">
              <div class="p-searchDetail__key__title">
                <b>性別</b>
              </div>
              <div class="p-searchDetail__key__caption">
                子どもの性別
              </div>
            </div>
            <div class="py-3 px-1 d-flex flex-wrap p-searchDetail__input">
              <div
                v-for="option in options.childSex"
                :key="option.value"
              >
                <v-checkbox
                  v-model="query.childSex"
                  :value="option.value"
                  :label="option.text"
                  class="ma-2"
                  hide-details
                />
              </div>
            </div>
          </div>
          <div
            v-if="query.departmentType != 'pediatrician'"
            class="d-flex p-searchDetail"
          >
            <div class="py-3 px-1 p-searchDetail__key">
              <div class="p-searchDetail__key__title">
                <b>年齢</b>
              </div>
              <div class="p-searchDetail__key__caption">
                相談者
              </div>
            </div>
            <div class="py-3 px-1 d-flex flex-wrap p-searchDetail__input">
              <div
                v-for="option in options.userAgeRank"
                :key="option.value"
              >
                <v-checkbox
                  v-model="query.userAgeRank"
                  :value="option.value"
                  :label="option.text"
                  class="ma-2"
                  hide-details
                />
              </div>
            </div>
          </div>
          <div
            v-if="query.departmentType != 'pediatrician'"
            class="d-flex p-searchDetail"
          >
            <div class="py-3 px-1 p-searchDetail__key">
              <div class="p-searchDetail__key__title">
                <b>妊娠状況</b>
              </div>
              <div class="p-searchDetail__key__caption">
                相談者
              </div>
            </div>
            <div class="py-3 px-1 d-flex flex-wrap p-searchDetail__input">
              <div
                v-for="option in options.inPregnancy"
                :key="option.value"
              >
                <v-checkbox
                  v-model="query.inPregnancy"
                  :value="option.value"
                  :label="option.text"
                  class="ma-2"
                  hide-details
                />
              </div>
            </div>
          </div>
          <div
            v-if="query.departmentType != 'pediatrician'"
            class="d-flex p-searchDetail"
          >
            <div class="py-3 px-1 p-searchDetail__key">
              <div class="p-searchDetail__key__title">
                <b>妊娠週数</b>
              </div>
              <div class="p-searchDetail__key__caption">
                相談者<br>(妊娠中のみ)
              </div>
            </div>
            <div class="py-3 px-1 d-flex flex-wrap p-searchDetail__input">
              <div
                v-for="option in options.weeksOfPregnancyRank"
                :key="option.value"
              >
                <v-checkbox
                  v-model="query.weeksOfPregnancyRank"
                  :value="option.value"
                  :label="option.text"
                  class="ma-2"
                  hide-details
                />
              </div>
            </div>
          </div>
          <div
            v-if="query.departmentType != 'pediatrician'"
            class="d-flex p-searchDetail"
          >
            <div class="py-3 px-1 p-searchDetail__key">
              <div class="p-searchDetail__key__title">
                <b>産後月数</b>
              </div>
              <div class="p-searchDetail__key__caption">
                相談者<br>(産後のみ)
              </div>
            </div>
            <div class="py-3 px-1 d-flex flex-wrap p-searchDetail__input">
              <div
                v-for="option in options.monthsOfPostpartumRank"
                :key="option.value"
              >
                <v-checkbox
                  v-model="query.monthsOfPostpartumRank"
                  :value="option.value"
                  :label="option.text"
                  class="ma-2"
                  hide-details
                />
              </div>
            </div>
          </div>
          <div class="d-flex p-searchDetail">
            <div class="py-3 px-1 p-searchDetail__key">
              <div class="p-searchDetail__key__title">
                <b>回答者名</b>
              </div>
              <div class="p-searchDetail__key__caption">
                回答者氏名<br>(部分一致)
              </div>
            </div>
            <div class="py-3 px-1 d-flex flex-wrap p-searchDetail__input">
              <v-text-field
                v-model="query.doctorFamilyName"
                class="pt-1 ml-2 mr-6"
                label="姓"
                density="compact"
                hide-details
                style="max-width: 120px;"
              />
              <v-text-field
                v-model="query.doctorFirstName"
                class="pt-1 ml-2 mr-6"
                label="名"
                density="compact"
                hide-details
                style="max-width: 120px;"
              />
            </div>
          </div>
          <div class="d-flex p-searchDetail">
            <div class="py-3 px-1 p-searchDetail__key">
              <div class="p-searchDetail__key__title">
                <b>気になっていること</b>
              </div>
              <div class="p-searchDetail__key__caption">
                事前問診より
              </div>
            </div>
            <div class="py-3 px-1 d-flex flex-wrap p-searchDetail__input">
              <div
                v-for="option in options.userInterest"
                :key="option.value"
              >
                <v-checkbox
                  v-model="query.userInterest"
                  :value="option.value"
                  :label="option.text"
                  class="ma-2"
                  hide-details
                />
              </div>
            </div>
          </div>

          <div class="text-center">
            <v-btn
              theme="dark"
              density="compact"
              @click="closeConditionDialog()"
            >
              完了
            </v-btn>
          </div>
        </v-container>
        <v-btn
          position="absolute"
          class="icon-button"
          color="gray-lighten-4"
          fab
          size="x-small"
          @click="closeConditionDialog()"
        >
          ×
        </v-btn>
      </v-col>
    </v-dialog>

    <!-- サイドバー本体部 -->
    <v-navigation-drawer
      class="p-drawer__main"
      :width="width"
      location="right"
      permanent
    >
      <!-- 最小化時の表示 -->
      <a
        v-if="isMinWidth"
        style="display:block; height: 100%; width: 100%;"
        @click="expand"
      >
        <v-icon class="pt-4 pl-1">search</v-icon>
      </a>
      <div v-else>
        <!-- タブ一覧 -->
        <v-tabs v-model="selectTabId" :height="40" bg-color="grey-lighten-3">
          <v-tab class="ma-0" :height="40" :key="0" :value="0">
            <v-icon>mdi-magnify</v-icon>
            <span class="font-weight-bold">過去事例検索</span>          
          </v-tab>
          <v-tab class="ma-0" :height="40" :key="1" :value="1">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.16927 17.5C3.93316 17.5 3.73524 17.4201 3.57552 17.2604C3.4158 17.1007 3.33594 16.9028 3.33594 16.6667V13.3333C3.33594 12.875 3.49913 12.4826 3.82552 12.1562C4.15191 11.8299 4.54427 11.6667 5.0026 11.6667H15.0026C15.4609 11.6667 15.8533 11.8299 16.1797 12.1562C16.5061 12.4826 16.6693 12.875 16.6693 13.3333V16.6667C16.6693 16.9028 16.5894 17.1007 16.4297 17.2604C16.27 17.4201 16.072 17.5 15.8359 17.5H4.16927ZM7.5026 10.8333C6.34983 10.8333 5.36719 10.4271 4.55469 9.61458C3.74219 8.80208 3.33594 7.81944 3.33594 6.66667C3.33594 5.51389 3.74219 4.53125 4.55469 3.71875C5.36719 2.90625 6.34983 2.5 7.5026 2.5H12.5026C13.6554 2.5 14.638 2.90625 15.4505 3.71875C16.263 4.53125 16.6693 5.51389 16.6693 6.66667C16.6693 7.81944 16.263 8.80208 15.4505 9.61458C14.638 10.4271 13.6554 10.8333 12.5026 10.8333H7.5026ZM7.5026 7.5C7.73872 7.5 7.93663 7.42014 8.09635 7.26042C8.25608 7.10069 8.33594 6.90278 8.33594 6.66667C8.33594 6.43056 8.25608 6.23264 8.09635 6.07292C7.93663 5.91319 7.73872 5.83333 7.5026 5.83333C7.26649 5.83333 7.06858 5.91319 6.90885 6.07292C6.74913 6.23264 6.66927 6.43056 6.66927 6.66667C6.66927 6.90278 6.74913 7.10069 6.90885 7.26042C7.06858 7.42014 7.26649 7.5 7.5026 7.5ZM12.5026 7.5C12.7387 7.5 12.9366 7.42014 13.0964 7.26042C13.2561 7.10069 13.3359 6.90278 13.3359 6.66667C13.3359 6.43056 13.2561 6.23264 13.0964 6.07292C12.9366 5.91319 12.7387 5.83333 12.5026 5.83333C12.2665 5.83333 12.0686 5.91319 11.9089 6.07292C11.7491 6.23264 11.6693 6.43056 11.6693 6.66667C11.6693 6.90278 11.7491 7.10069 11.9089 7.26042C12.0686 7.42014 12.2665 7.5 12.5026 7.5Z" fill="#1564BD"/>
            </svg>            
            <span class="font-weight-bold">AI添削</span>          
          </v-tab>
        </v-tabs>
        <div v-if="selectTabId == 0">
        <!-- <v-tabs-window v-model="selectTabId"> -->
          <!-- タブ「事例を探す」 -->
          <!-- <v-tabs-window-item
            :key="0"
            :value="0"
          > -->
            <template v-if="elasticsearchErrorDetail.length > 0">
              <div class="pa-6">
                <div v-html="structuredElasticsearchErrorDetail" />
                <div class="mt-4">
                  <a href="/anytime_consultations/search">回答済み検索(旧検索画面)へ</a>
                </div>
              </div>
            </template>
            <template v-else>
              <v-container
                fluid
                class="p-searchConditions"
              >
                <v-row
                  :dense="true"
                  style="border-bottom: 1px solid #ddd;"
                >
                  <v-col cols="3">
                    <div class="pa-2">
                      <b>相談員種別</b>
                    </div>
                  </v-col>
                  <v-col cols="9">
                    <v-radio-group
                      v-model="query.departmentType"
                      inline
                      density="compact"
                      class="ma-0"
                    >
                      <v-radio
                        v-for="option in options.departmentType"
                        :key="option.value"
                        :label="option.text"
                        :value="option.value"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row :dense="true">
                  <v-col cols="3">
                    <div class="pa-2">
                      <b>キーワード</b>
                    </div>
                  </v-col>
                  <v-col cols="9">
                    <v-text-field
                      v-model="query.answerKeywords"
                      density="compact"
                      hide-details
                      class="pa-0"
                      placeholder="スペース区切り キーワード"
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="3">
                    <div
                      class="pa-2 ml-2"
                      style="font-size: 12.8px;"
                    >
                      検索範囲
                    </div>
                  </v-col>
                  <v-col cols="9">
                    <div class="d-flex">
                      <div
                        v-for="option in options.answerKeywordScopes"
                        :key="option.value"
                      >
                        <v-checkbox
                          v-model="query.answerKeywordScopes"
                          :value="option.value"
                          :label="option.text"
                          class="mt-1 mr-3 c-checkbox--mini"
                          hide-details
                        />
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <v-row
                  :dense="true"
                  style="border-top: 1px solid #ddd;"
                >
                  <v-col cols="3">
                    <div class="pa-2">
                      <b>暫定診断</b>
                    </div>
                  </v-col>
                  <v-col cols="9">
                    <div class="icd10Search">
                      <v-text-field
                        id="icd10SearchField"
                        v-model="query.icd10Keyword"
                        class="pa-0"
                        placeholder="診断名を検索"
                        hide-details
                        @update:modelValue="searchIcd10"
                        @focus="onFocusIcd10Search(true)"
                        @blur="onFocusIcd10Search(false)"
                      />

                      <div
                        v-if="icd10SearchFocus"
                        class="icd10ResultField"
                      >
                        <div :class="{ icd10ListWrap: icd10SearchResults.length !== 0 }">
                          <div class="icd10ListArea">
                            <div
                              v-for="result in icd10SearchResults"
                              :key="result.id"
                            >
                              <p @click="onSelectDiagnosis(result)">
                                <a>{{ result.name }}</a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <v-row
                  v-if="hasOnceSearched && !isSearching && query.icd10Keyword.length == 0 && topFiveIcdBranch.length > 0"
                  no-gutters
                  class="mb-2"
                >
                  <v-col cols="3">
                    <div
                      class="pa-2 ml-2"
                      style="font-size: 12.8px;"
                    >
                      予測候補
                    </div>
                  </v-col>
                  <v-col cols="9">
                    <div
                      class="mt-1"
                      style="color: #999;font-size: small;display: flex; flex-direction: column;"
                    >
                      <a
                        v-for="IcdBranch in topFiveIcdBranch"
                        :key="IcdBranch.name"
                        class="c-icd10-candidate"
                        @click="setIcd10Keyword(IcdBranch.name)"
                      >{{ IcdBranch.name }}</a>
                    </div>
                  </v-col>
                </v-row>
                <v-row style="border-top: 1px solid #ddd; border-bottom: 1px solid #ddd;">
                  <v-col cols="3">
                    <div class="pa-2">
                      <b>追加条件</b>
                    </div>
                  </v-col>
                  <v-col cols="9">
                    <div class="py-2">
                      <div v-if="displayQuery.length > 0">
                        {{ displayQuery }}
                      </div>
                      <a @click="openConditionDialog()">
                        ＋ 設定する
                      </a>
                    </div>
                  </v-col>
                </v-row>
                <v-row
                  v-if="isOperator"
                  style="border-bottom: 1px solid #ddd;"
                >
                  <v-col cols="3">
                    <div class="pa-2">
                      <b>管理者向け</b>
                    </div>
                  </v-col>
                  <v-col cols="9">
                    <div class="py-2">
                      <div v-if="displayOption.length > 0">
                        {{ displayOption }}
                      </div>
                      <a @click="openOptionDialog()">
                        ＋ 設定する
                      </a>
                    </div>
                  </v-col>
                </v-row>
                <v-row :dense="true">
                  <v-col class="text-center">
                    <v-btn
                      color="grey-darken-4"
                      density="comfortable"
                      @click="updateList()"
                    >
                      検索
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
              <div v-if="hasOnceSearched">
                <div
                  v-if="isSearching"
                  class="pa-6 text-center"
                >
                  <!-- <img src="/loading.gif"> -->
                </div>
                <template v-else>
                  <div class="pa-4">
                    <template v-if="hit >= 100">
                      100件以上ヒット/関連度順に表示(最初の100件のみを表示)
                    </template>
                    <template v-else>
                      {{ hit }}件ヒット/関連度順に表示
                    </template>
                  </div>
                  <div v-if="anytimeConsultations.length > 0">
                    <AnytimeConsultationElasticSearchResult
                      v-for="anytimeConsultation in anytimeConsultations"
                      :key="anytimeConsultation.id"
                      :consultation="anytimeConsultation"
                      :keywords="previousSplitKeywords"
                    />
                  </div>
                  <div
                    v-else
                    class="pa-4"
                  >
                    検索条件に一致する事例はありませんでした。<br>
                    検索条件を変えて、もう一度お試しください。
                  </div>
                </template>
              </div>
              <div
                v-else
                class="pa-6"
              >
                過去のいつでも相談の回答から、参考になる回答を探すことができます。<br>
                検索条件を入力し、「検索」ボタンを押してください。<br>
                <br>
                「◀︎」「▶️」ボタンを押すことで、このエリアの大きさを調整できます。
              </div>
            </template>
          </div>
          <!-- </v-tabs-window-item> -->
          <!-- タブ「回答文添削」 -->
          <div v-else-if="selectTabId == 1">
          <!-- <v-tabs-window-item
            :key="1"
            :value="1"
          > -->
            <div>
              <v-progress-linear
                v-if="isCorrecting"
                indeterminate
                striped
                height="4"
                color="rgb(21,100,189)"
              />
            </div>
            <div style="font-size: 10px; color: #A5A5A5" class="text-right mt-2">
              ※この機能はClaude 3.5 Sonnetを使用しています。
            </div>
            <div class="text-center my-4" v-if="orderedCorrectNoteList.length == 0 && !isCorrecting && !isCorrectionSuccess">
              <v-btn color="rgb(21,100,189)" size="large" class="px-8" @click="executeCorrection">
                <svg class="mr-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.16927 17.5C3.93316 17.5 3.73524 17.4201 3.57552 17.2604C3.4158 17.1007 3.33594 16.9028 3.33594 16.6667V13.3333C3.33594 12.875 3.49913 12.4826 3.82552 12.1562C4.15191 11.8299 4.54427 11.6667 5.0026 11.6667H15.0026C15.4609 11.6667 15.8533 11.8299 16.1797 12.1562C16.5061 12.4826 16.6693 12.875 16.6693 13.3333V16.6667C16.6693 16.9028 16.5894 17.1007 16.4297 17.2604C16.27 17.4201 16.072 17.5 15.8359 17.5H4.16927ZM7.5026 10.8333C6.34983 10.8333 5.36719 10.4271 4.55469 9.61458C3.74219 8.80208 3.33594 7.81944 3.33594 6.66667C3.33594 5.51389 3.74219 4.53125 4.55469 3.71875C5.36719 2.90625 6.34983 2.5 7.5026 2.5H12.5026C13.6554 2.5 14.638 2.90625 15.4505 3.71875C16.263 4.53125 16.6693 5.51389 16.6693 6.66667C16.6693 7.81944 16.263 8.80208 15.4505 9.61458C14.638 10.4271 13.6554 10.8333 12.5026 10.8333H7.5026ZM7.5026 7.5C7.73872 7.5 7.93663 7.42014 8.09635 7.26042C8.25608 7.10069 8.33594 6.90278 8.33594 6.66667C8.33594 6.43056 8.25608 6.23264 8.09635 6.07292C7.93663 5.91319 7.73872 5.83333 7.5026 5.83333C7.26649 5.83333 7.06858 5.91319 6.90885 6.07292C6.74913 6.23264 6.66927 6.43056 6.66927 6.66667C6.66927 6.90278 6.74913 7.10069 6.90885 7.26042C7.06858 7.42014 7.26649 7.5 7.5026 7.5ZM12.5026 7.5C12.7387 7.5 12.9366 7.42014 13.0964 7.26042C13.2561 7.10069 13.3359 6.90278 13.3359 6.66667C13.3359 6.43056 13.2561 6.23264 13.0964 6.07292C12.9366 5.91319 12.7387 5.83333 12.5026 5.83333C12.2665 5.83333 12.0686 5.91319 11.9089 6.07292C11.7491 6.23264 11.6693 6.43056 11.6693 6.66667C11.6693 6.90278 11.7491 7.10069 11.9089 7.26042C12.0686 7.42014 12.2665 7.5 12.5026 7.5Z" fill="white"/>
                </svg>
                AIに添削をお願いする(テスト版)
              </v-btn>
              <div style="font-size: 10px">
                ※AI添削は正確ではない場合があります、<br>
                ご自身でも内容をご確認の上送信予約をしてください。
              </div>
            </div>
            <AutoCorrectionList />
          </div>
          <!-- </v-tabs-window-item> -->
        <!-- </v-tabs-window> -->
      </div>
    </v-navigation-drawer>
  </div>
</template>

<style lang="scss">
.c-checkbox--mini {
  .v-label {
    font-size: 0.8em !important;
  }

  .v-input--selection-controls__input {
    margin-right: 0px !important;
  }

  .v-input--selection-controls__ripple {
    height: 31px;
    width: 31px;
    top: calc(50% - 23px);
  }

  .v-icon {
    font-size: 21px !important;
  }
}

.c-switch--mini {
  .v-label {
    font-size: 0.8em !important;
  }
}

.c-radio--mini {
  .v-label {
    font-size: 0.8em !important;
  }

  .v-input--selection-controls__input {
    margin-right: 0px !important;
  }

  .v-input--selection-controls__ripple {
    height: 31px;
    width: 31px;
    top: calc(50% - 23px);
  }

  .v-icon {
    font-size: 21px !important;
  }
}

.c-icd10-candidate {
  text-decoration: underline;
  color: #999 !important;
}

.p-drawer {
  &__icons {
    position: absolute;
    z-index: 2000;
    /* v-navigation-drawerになぜか動的にz-indexが指定される */
    margin-left: -12px;
    display: flex;
    flex-direction: column;
    margin-top: 50px;

    &__expand {
      transform: rotate(180deg);
      height: 25px;

      &.is-disable {
        opacity: 0;
      }
    }

    &__shrink {
      height: 25px;
      margin-top: 8px;

      &.is-disable {
        opacity: 0;
      }
    }
  }

  &__main {
    border-left: 1px solid #C9C5C5;
    height: 100vh !important;
    overflow: auto;
  }
}

.p-searchConditions {
  background-color: #eee;

  .v-messages {
    min-height: 0px !important;
  }

  .v-input__slot {
    margin: 0;
  }

  .icd10Search {
    .icd10ResultField {
      position: relative;
    }

    .icd10ListWrap {
      z-index: 2;
      position: absolute;
      width: 100%;
      top: 0;

      .icd10ListArea {
        max-height: 150px;
        border: solid 1px #000000;
        margin: 0 auto;
        overflow: auto;
        background-color: #ffffff;
      }

      p {
        margin: 0;
        text-align: left;
        border: solid 1px #d2d2d2;

        a {
          display: inline-block;
          width: 100%;

          &:hover {
            background-color: #ccc;
          }
        }
      }
    }
  }
}

.p-searchDetail {
  &+& {
    border-top: 1px solid #ccc;
  }

  &__key {
    width: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__caption {
      font-size: 0.7em;
      color: #666
    }
  }

  &__input {
    width: calc(100% - 120px);
  }
}

.p-drawer {
  .v-navigation-drawer {
    /* absoluteがつくとflexでうまく配置されない */
    position: initial !important;
  }
}

.c-ai-assitant-title {
  position: relative;

  &.is-connecting::after {
    content: "";
    position: absolute;
    top: 0px;
    right: -10px;
    width: 6px;
    height: 6px;
    background-color: red;
    border-radius: 50%;
  }
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import AnytimeConsultationElasticSearchResult from "./AnytimeConsultationElasticSearchResult.vue";
import AutoCorrectionList from "./AutoCorrectionList.vue";

export default {
  name: "AnytimeConsultationSearchBar",
  components: {
    AnytimeConsultationElasticSearchResult,
    AutoCorrectionList
  },
  props: {
    widthRank: {
      type: Number,
      default: 2
    }
  },
  emits: ["updateWidthRank"],
  data: () => ({
    // === 全体 ======
    // サイドバー全体の表示の切り替え
    widthRanks: {
      1: 30,
      2: (document.body.clientWidth * 1 / 3),
      3: (document.body.clientWidth * 3 / 5),
    },
    searchConditionDialog: false,
    optionDialog: false,
    hasOnceSearched: false,

    // タブ切り替え
    selectTabId: 0,

    // === 事例検索 ======
    // 検索条件
    query: {
      // 診療科
      departmentType: null,
      // 小児科
      childAgeRank: [],
      childSex: [],
      // 産婦人科
      inPregnancy: [],
      userAgeRank: [],
      weeksOfPregnancyRank: [],
      monthsOfPostpartumRank: [],
      // 共通
      userInterest: [],
      icd10Keyword: "",
      answerKeywords: "",
      answerKeywordScopes: ["answer"],
      doctorFamilyName: "",
      doctorFirstName: "",
      // 表示オプション
      displayScore: false,
      sortRules: "score"
    },
    previousQuery: {
      answerKeywords: []
    },
    options: {
      // 診療科
      departmentType: [
        { text: "小児科", value: "pediatrician" },
        { text: "産婦人科", value: "obstetrician" },
        { text: "助産師", value: "midwife" },
      ],
      // 小児科/産婦人科共通
      userInterest: [
        { text: "家でのケアの方法", value: 1 },
        { text: "病院受診の目安", value: 2 },
        { text: "原因", value: 3 },
        { text: "薬の使い方", value: 4 },
        { text: "その他", value: 5 },
      ],
      // 小児科のみ
      childAgeRank: [
        { text: "生後1ヶ月未満", value: 1 },
        { text: "1ヶ月以上〜7ヶ月未満", value: 2 },
        { text: "7ヶ月以上〜1歳未満", value: 3 },
        { text: "1歳以上〜6歳未満", value: 4 },
        { text: "6歳以上", value: 5 },
      ],
      childSex: [
        { text: "男の子", value: 0 },
        { text: "女の子", value: 1 },
      ],
      // 産婦人科のみ
      userAgeRank: [
        { text: "-14歳", value: 1 },
        { text: "15-19歳", value: 2 },
        { text: "20-24歳", value: 3 },
        { text: "25-29歳", value: 4 },
        { text: "30-34歳", value: 5 },
        { text: "35-39歳", value: 6 },
        { text: "40-44歳", value: 7 },
        { text: "45-49歳", value: 8 },
        { text: "50-54歳", value: 9 },
        { text: "55-59歳", value: 10 },
        { text: "60歳-", value: 11 },
      ],
      inPregnancy: [
        { text: "妊娠中", value: "pregnant" },
        { text: "産後", value: "postpartum" },
        { text: "その他", value: "others" },
      ],
      weeksOfPregnancyRank: [
        { text: "-14週", value: 1 },
        { text: "15-21週", value: 2 },
        { text: "22-27週", value: 3 },
        { text: "28-33週", value: 4 },
        { text: "34-36週", value: 5 },
        { text: "37週-", value: 6 },
      ],
      monthsOfPostpartumRank: [
        { text: "1ヶ月未満", value: 1 },
        { text: "1-3ヶ月", value: 2 },
        { text: "4-6ヶ月", value: 3 },
        { text: "7-11ヶ月", value: 4 },
        { text: "12-17ヶ月", value: 5 },
        { text: "18-23ヶ月", value: 6 },
        { text: "24ヶ月-", value: 7 },
      ],
      // その他
      answerKeywordScopes: [
        { text: "回答文", value: "answer" },
        { text: "質問文", value: "question" },
        { text: "主訴", value: "complaint" },
      ],
      sortRules: [
        { text: "関連度順", value: "score" },
        { text: "新しい順", value: "time_desc" },
        { text: "古い順", value: "time_asc" },
      ]
    },
    // === 事例検索 ======

    // 暫定診断検索
    icd10SearchFocus: false,
    icd10SearchResults: [],

  }),
  computed: {
    ...mapGetters([
      "anytimeConsultation",
      "anytimeConsultations",
      "anytimeConsultationAnswer",
      "anytimeConsultationSearchKeys",
      "isSearching",
      "isCorrecting",
      "isCorrectionSuccess",
      "orderedCorrectNoteList",
      "elasticsearchErrorDetail",
      "currentDoctor"
    ]),
    width: function () {
      return this.widthRanks[this.widthRank];
    },
    isMinWidth: function () {
      return this.widthRank == 1;
    },
    isMaxWidth: function () {
      return this.widthRank == 3;
    },
    splitKeywords: function () {
      return this.query.answerKeywords.split(/\s|'　'/).filter(v => v);
    },
    previousSplitKeywords: function () {
      if (typeof this.previousQuery != "object" || typeof this.previousQuery.answerKeywords != "string") {
        return [];
      }
      return this.previousQuery.answerKeywords.split(/\s|'　'/).filter(v => v);
    },
    hit: function () {
      return this.anytimeConsultations.length;
    },
    topFiveIcdBranch: function () {
      if (typeof this.anytimeConsultations != "object" || this.anytimeConsultations.length == 0) {
        return [];
      }
      var rankings = {};
      var score = 30;
      this.anytimeConsultations
        .map(function (anytimeConsultation) { return anytimeConsultation.icd10BranchName; })
        .filter(v => v) // nullを除外
        .slice(0, 30) // 上位30件までに絞る
        .forEach(function (icd10BranchName) {
          if (icd10BranchName in rankings) {
            rankings[icd10BranchName] += score;
          } else {
            rankings[icd10BranchName] = score;
          }
          score -= 1;
        });
      return Object.keys(rankings).map(function (key) {
        return { "score": rankings[key], "name": key };
      }).sort(function (a, b) {
        return b.score - a.score;
      }).slice(0, 5);
    },
    displayQuery() {
      let condArray = [];
      // 共通
      if (this.query.userInterest.length > 0) {
        condArray = condArray.concat(this.query.userInterest.map(choice => this.options.userInterest.find(a => a.value === choice).text));
      }
      if (this.query.doctorFamilyName.length > 0 || this.query.doctorFirstName.length > 0) {
        condArray = condArray.concat([`回答者:${this.query.doctorFamilyName}${this.query.doctorFirstName}`]);
      }
      if (this.query.departmentType == "pediatrician") {
        // 小児科
        if (this.query.childAgeRank.length > 0) {
          condArray = condArray.concat(this.query.childAgeRank.map(choice => this.options.childAgeRank.find(a => a.value === choice).text));
        }
        if (this.query.childSex.length > 0) {
          condArray = condArray.concat(this.query.childSex.map(choice => this.options.childSex.find(a => a.value === choice).text));
        }
      } else {
        // 産婦人科
        if (this.query.userAgeRank.length > 0) {
          condArray = condArray.concat(this.query.userAgeRank.map(choice => this.options.userAgeRank.find(a => a.value === choice).text));
        }
        if (this.query.inPregnancy.length > 0) {
          condArray = condArray.concat(this.query.inPregnancy.map(choice => this.options.inPregnancy.find(a => a.value === choice).text));
        }
        if (this.query.weeksOfPregnancyRank.length > 0) {
          condArray = condArray.concat(this.query.weeksOfPregnancyRank.map(choice => this.options.weeksOfPregnancyRank.find(a => a.value === choice).text));
        }
        if (this.query.monthsOfPostpartumRank.length > 0) {
          condArray = condArray.concat(this.query.monthsOfPostpartumRank.map(choice => this.options.monthsOfPostpartumRank.find(a => a.value === choice).text));
        }
      }
      return condArray.join(", ");
    },
    displayOption: function () {
      let condArray = [];
      condArray.push("表示順:" + this.options.sortRules.find(a => a.value === this.query.sortRules).text);
      if (this.query.displayScore) {
        condArray.push("スコア表示あり");
      }
      return condArray.join(", ");
    },
    structuredElasticsearchErrorDetail: function () {
      const content = this.$sanitize(this.elasticsearchErrorDetail);
      if (typeof content !== "string" || content.length == 0) {
        return "";
      }
      return content.replaceAll(/\r\n/g, "<br>").replace(/\r/g, "<br>").replace(/\n/g, "<br>");
    },
    isOperator() {
      if (this.currentDoctor === null) {
        return false;
      }
      return this.currentDoctor.type == "Operator::Doctor";
    },
  },
  watch: {
    widthRank(newValue) {
      this.$emit("updateWidthRank", newValue);
    }
  },
  // ▼開いた瞬間に検索を実行
  created: function () {
    this.$store.watch(
      (state, getters) => getters.anytimeConsultation,
      (anytimeConsultation) => {
        this.query.departmentType = anytimeConsultation.department_type;
        if (!this.hasOnceSearched) {
          this.updateList();
        }
      }
    );
  },
  mounted: function () {
    if (this.anytimeConsultation.id == this.$route.params.id) {
      this.query.departmentType = this.anytimeConsultation.department_type;
      if (!this.hasOnceSearched) {
        this.updateList();
      }
    }
    window.addEventListener("beforeunload", this.handleBeforeUnload);
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  },
  // ▲開いた瞬間に検索を実行
  methods: {
    ...mapActions([
      "elasticsearchAnytimeConsultations",
      "assignAnytimeConsultationSearchKeys",
      "execCorrectionGpt"
    ]),
    expand: function () {
      if (this.isMaxWidth) {
        return;
      }
      this.$emit("updateWidthRank", this.widthRank + 1);
    },
    shrink: function () {
      if (this.isMinWidth) {
        return;
      }
      this.$emit("updateWidthRank", this.widthRank - 1);
    },
    updateList: function () {
      this.elasticsearchAnytimeConsultations({ id: this.$route.params.id, query: this.query });
      this.previousQuery = JSON.parse(JSON.stringify(this.query));
      this.hasOnceSearched = true;
    },
    onSelectDiagnosis(item) {
      this.query.icd10Keyword = item.name;
      this.icd10SearchResults = []; // 検索リストを消す
    },
    onFocusIcd10Search(focus) {
      // サジェストされた診断名をクリックしようとしてもフォーカスが外れるが、
      // すぐに icd10SearchFocus = false するとサジェスト結果が消えてしまい
      // クリックイベントがトリガーされず onSelectDiagnosis が動作しないため、
      // クリックイベントがトリガーされて onSelectDiagnosis が動作するのを少し待つ
      setTimeout(() => {
        this.icd10SearchFocus = focus;
      }, 300);
    },
    searchIcd10: function () {
      axios.get("/api/v1/icd10/all_types/search", {
        params: {
          keyword: this.query.icd10Keyword
        }
      }).then(response => {
        this.icd10SearchResults = response.data;
      }).catch(error => {
        console.log(error);
      });
    },
    openConditionDialog: function () {
      this.searchConditionDialog = true;
    },
    openOptionDialog: function () {
      this.optionDialog = true;
    },
    closeConditionDialog: function () {
      this.searchConditionDialog = false;
    },
    closeOptionDialog: function () {
      this.optionDialog = false;
    },
    setIcd10Keyword: function (icd10BranchName) {
      this.query.icd10Keyword = icd10BranchName;
    },
    executeCorrection() {
      this.selectTabId = 1;
      document.getElementsByClassName("layeditor")[0].scrollIntoView({behavior: "smooth"});
      this.execCorrectionGpt({ id: this.$route.params.id, cable: this.$cable, answer: this.anytimeConsultationAnswer.content });
    },
    handleBeforeUnload(event) {
      if (this.openaiChannel) {
        this.openaiChannel.unsubscribe();
      }
    },
  }
};
</script>